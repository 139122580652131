/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "verwarming/verwarming13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "verwarming/verwarming13.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(
      relativePath: { eq: "verwarming/verwarming12.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal2Image: file(
      relativePath: { eq: "verwarming/verwarming13.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Vloerverwarming voor optimaal comfort`,
  textWithLink: () => (
    <>
      <p>
        Wat is er lekkerder dan thuiskomen, uw schoenen uittrekken en over een
        verwarmde vloer lopen? Niet voor niets wordt vloerwarming ervaren als
        één van de meest aangename vormen van verwarming. Door de gelijkmatige
        warmtestraling vanuit de gehele vloeroppervlakte, is de warmteafgifte
        overal gelijk. Vloerverwarming is tegenwoordig ook prima te combineren
        met natuursteen en zelfs houten vloeren.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0642090304'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
    </>
  ),
};

const Vloerverwarming = ({ data, path, location }) => {
  const seo = {
    title: `Vloerverwarming`,
    description: `Wij zijn uw vakkundige installateur voor de aanleg van vloerverwarming`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Voordelen van vloerverwarming',
    inner:
      'Vloerverwarming kan toegepast worden als hoofd- of bijverwarming. Kiest u voor hoofdverwarming, dan zijn radiatoren vrijwel niet meer nodig. Hierdoor heeft u meer vrijheid bij het inrichten van uw woning. Daarnaast resulteert vloerverwarming in energiebesparing doordat de thermostaat gemiddeld twee graden lager gezet kan worden. Ook is het vanwege de geringe stofcirculatie ideaal voor mensen met een huisstofallergie.',
  };

  const CardHorizontal2 = {
    textFirst: true,
    noSpace: true,
    img: data.CardHorizontal2Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Deskundige installatie',
    inner: `Wij zijn uw vakkundige installateur voor de aanleg van vloerverwarming. Of u nu kiest voor in gefreesde leidingen of de elektrische variant voor in de badkamer: alles wordt compleet aangelegd, geïnstalleerd en afgemonteerd. Ook stellen we de thermostaat perfect af op uw wensen, zodat u 24/7 geniet van een heerlijk klimaat.`,
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Voor al uw Vloerverwarming`,
    pitchLine: `Laat al uw vloerverwarming over aan onze professionals.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        CardHorizontal2={CardHorizontal2}
      />
    </>
  );
};

Vloerverwarming.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Vloerverwarming;
